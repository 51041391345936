import {
  ExternalLinkIcon,
  HeartIcon as OutlineHeartIcon,
} from '@heroicons/react/outline';
import {
  ChevronRightIcon,
  HeartIcon as SolidHeartIcon,
} from '@heroicons/react/solid';
import { AxiosError } from 'axios';
import Image from 'next/image';
import Link from 'next/link';
import { MouseEvent, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  getContentApi,
  redirectContentApi,
  toggleContentFavApi,
  viewContentApi,
} from '../../../lib/apis/projects';
import { axiosErrorHandler } from '../../../lib/functions/handler';
import { elapsedTime } from '../../../lib/functions/utils';
import { ProjectType } from '../../../lib/types/enums';
import { Project } from '../../../lib/types/project';
import { useAppSelector } from '../../../redux/app/hooks';

interface ContentContainerProps {
  projectId: string;
  title: string;
  imageUrl?: string;
  summary: string;
  siteUrl: string;
  onBoardDate: Date;
  tagString: string;
  className?: string;
}

const ContentContainer: React.FC<ContentContainerProps> = ({
  projectId,
  title,
  imageUrl,
  summary,
  siteUrl,
  onBoardDate,
  tagString,
  className,
}) => {
  const user = useAppSelector((state) => state.users);
  const [isFav, setIsFav] = useState(false);
  const {
    data: contentData,
    isLoading: isContentLoading,
    isError: isContentError,
  } = useQuery<Project, Error>(['content', projectId], () =>
    getContentApi({ contentId: projectId }),
  );
  const { mutate: mutateViewContent } = useMutation(
    ['viewContent'],
    viewContentApi,
  );
  const { mutate: mutateRedirectContent } = useMutation(
    ['redirectContent'],
    redirectContentApi,
  );
  const {
    mutate: mutateToggleContentFav,
    isLoading: isToggleContentFavLoading,
  } = useMutation(['toggleContentFav'], toggleContentFavApi, {
    onSuccess: () => {
      setIsFav((prevState) => !prevState);
    },
    onError: (e: AxiosError) => {
      axiosErrorHandler(e);
    },
  });

  useEffect(() => {
    if (!isContentLoading && !isContentError) {
      if (contentData) {
        if (user.isLoggedIn) {
          const { favUsers } = contentData;
          const favUsersId = favUsers.map((user) => user.id);
          if (favUsersId.includes(user.userId)) {
            setIsFav(true);
          }
        }
      }
    }
  }, [user, contentData, isContentLoading, isContentError]);

  const viewContent = () => {
    mutateViewContent({ contentId: projectId });
  };

  const redirectContent = () => {
    mutateRedirectContent({ contentId: projectId });
  };

  const onHeartButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    mutateToggleContentFav({ contentId: projectId });
  };

  const onShareButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    if (navigator.share) {
      // Web Share API 브라우저 호환성
      // https://developer.mozilla.org/en-US/docs/Web/API/Web_Share_API#browser_compatibility
      const shareData = {
        title,
        text: summary,
        url: siteUrl,
      };
      navigator.share(shareData);
    } else if (navigator.clipboard && navigator.clipboard.writeText) {
      // navigator.clipboard 브라우저 호환성
      // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/clipboard#browser_compatibility
      navigator.clipboard.writeText(siteUrl).then(
        () => {
          alert('링크가 복사되었습니다!');
        },
        () => {
          alert('링크 복사에 실패하였습니다.');
        },
      );
    } else {
      // 위의 두 경우 모두 호환되지 않을 때 (iOS 13.4 미만)
      const textarea = document.createElement('textarea');
      textarea.value = siteUrl;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'fixed';
      textarea.style.top = '0';
      textarea.style.left = '0';
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      const result = document.execCommand('copy');
      document.body.removeChild(textarea);
      if (result) {
        alert('링크가 복사되었습니다!');
      } else {
        alert('링크 복사에 실패하였습니다.');
      }
    }
  };

  return (
    <div className={`lg:hover:content-container group ${className}`}>
      <Link href={siteUrl}>
        <a
          onClick={redirectContent}
          target="_blank"
          className="flex w-full flex-col space-y-2 md:space-y-[14px]"
        >
          <div className="relative w-full overflow-hidden rounded-2xl bg-gradient-to-br from-gray-100 to-gray-200">
            <div className="aspect-w-16 aspect-h-9">
              <Image
                src={imageUrl || '/assets/img/alert/no-content.png'}
                alt={`content-${title}-thumbnail`}
                layout="fill"
                objectFit="cover"
                priority
              />
              {!!!imageUrl && (
                <div className="absolute top-0 left-0 h-full w-full bg-gray-800 opacity-10" />
              )}
            </div>
            <div className="content-image-shadow absolute inset-x-0 bottom-0 hidden h-[78px] w-full items-end justify-end space-x-4 pr-3 pb-3 transition lg:group-hover:flex">
              <button
                onClick={(e) => onHeartButtonClick(e)}
                disabled={isToggleContentFavLoading}
                className="rounded-md bg-white p-[6px] transition hover:bg-gray-200 active:scale-75"
              >
                {isFav ? (
                  <SolidHeartIcon
                    width={24}
                    height={24}
                    className="text-rose-400"
                  />
                ) : (
                  <OutlineHeartIcon width={24} height={24} />
                )}
              </button>
              <button
                onClick={(e) => onShareButtonClick(e)}
                className="rounded-md bg-white p-[6px] transition hover:bg-gray-200 active:scale-75"
              >
                <ExternalLinkIcon width={24} height={24} />
              </button>
            </div>
          </div>

          <div className="flex items-center justify-between text-xs font-semibold text-gray-500">
            <div className="flex h-[26px] w-[58px] items-center justify-center rounded-2xl border border-gray-200">
              {contentData?.projectType === ProjectType.service
                ? '서비스'
                : '콘텐츠'}
            </div>
            <Link href={`/contents/${projectId}`}>
              <a
                onClick={viewContent}
                className="flex h-[45px] w-24 items-center justify-end hover:opacity-70 md:h-fit md:w-fit"
              >
                <div>상세보기</div>
                <ChevronRightIcon className="h-4 w-4" />
              </a>
            </Link>
          </div>

          <span className="text-lg font-bold text-gray-900 line-clamp-1">
            {title}
          </span>

          <div className="flex items-center justify-between space-x-4 pt-2 text-xs md:pt-0">
            <div className="min-w-fit text-gray-500">
              {elapsedTime(onBoardDate)}
            </div>
            <div className="flex space-x-[6px] line-clamp-1">
              {tagString &&
                tagString
                  .split(',')
                  .slice(0, 3)
                  .map((tag) => (
                    <Link key={`${title}-${tag}`} href={`/search/tags/${tag}`}>
                      <a className="text-swygBlue-600 hover:opacity-70">
                        {`#${tag}`}
                      </a>
                    </Link>
                  ))}
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default ContentContainer;
