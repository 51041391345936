import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import ContentContainer from '../ContentContainer/ContentContainer';
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from 'react-query';
import { GetContentsResponse } from '../../../lib/apis/projects/response';

interface ContentsContainerProps {
  setPage: Dispatch<SetStateAction<number>>;
  contents: InfiniteData<GetContentsResponse> | undefined;
  isLoading: boolean;
  isError: boolean;
  hasNextPage: boolean | undefined;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<GetContentsResponse, Error>>;
  isFetchingNextPage: boolean;
}

const ContentsContainer: React.FC<ContentsContainerProps> = ({
  setPage,
  contents,
  isLoading,
  isError,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
}) => {
  const contentsDivRef = useRef<HTMLDivElement>(null);
  const skeletons = [1, 2, 3, 4, 5, 6, 7, 8];

  const loadMoreCallback = useCallback(() => {
    if (hasNextPage) {
      setPage((prevState) => prevState + 1);
      fetchNextPage();
    }
  }, [hasNextPage, setPage, fetchNextPage]);

  useEffect(() => {
    function reachBottom() {
      if (contentsDivRef.current) {
        const { bottom } = contentsDivRef.current.getBoundingClientRect();
        const isBottom = bottom < window.innerHeight;
        if (isBottom) {
          loadMoreCallback();
        }
      }
    }
    document.addEventListener('scroll', reachBottom);
    if (!hasNextPage) {
      document.removeEventListener('scroll', reachBottom);
    }
    return () => {
      document.removeEventListener('scroll', reachBottom);
    };
  }, [contentsDivRef, loadMoreCallback, hasNextPage]);

  return (
    <div
      ref={contentsDivRef}
      className="grid gap-x-6 gap-y-[60px] pb-4 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4"
    >
      {contents
        ? contents.pages.map((group, i) => (
            <Fragment key={`content-page-${i}`}>
              {group?.contents?.map((content) => (
                <ContentContainer
                  key={`content-project-${content.id}`}
                  projectId={content.id}
                  tagString={content.tagString}
                  imageUrl={content.projectImages[0]?.imageFileLocation}
                  siteUrl={content.siteUrl}
                  summary={content.summary}
                  title={content.title}
                  onBoardDate={content.onBoardDate}
                />
              ))}
              {isFetchingNextPage &&
                skeletons.map((skeleton) => (
                  <div
                    key={skeleton.toString()}
                    className="flex w-full flex-col space-y-4"
                  >
                    <div className="aspect-w-16 aspect-h-9 rounded-2xl bg-gray-200"></div>
                    <div className="skeleton-text-container w-[78px]"></div>
                    <div className="skeleton-text-container w-full"></div>
                    <div className="flex justify-between">
                      <div className="skeleton-text-container w-[52px]"></div>
                      <div className="flex space-x-1">
                        <div className="skeleton-text-container w-[52px]"></div>
                        <div className="skeleton-text-container w-[52px]"></div>
                        <div className="skeleton-text-container w-[52px]"></div>
                      </div>
                    </div>
                  </div>
                ))}
            </Fragment>
          ))
        : (isLoading || isError) &&
          skeletons.map((skeleton) => (
            <div
              key={skeleton.toString()}
              className="flex w-full flex-col space-y-4"
            >
              <div className="aspect-w-16 aspect-h-9 rounded-2xl bg-gray-200"></div>
              <div className="skeleton-text-container w-[78px]"></div>
              <div className="skeleton-text-container w-full"></div>
              <div className="flex justify-between">
                <div className="skeleton-text-container w-[52px]"></div>
                <div className="flex space-x-1">
                  <div className="skeleton-text-container w-[52px]"></div>
                  <div className="skeleton-text-container w-[52px]"></div>
                  <div className="skeleton-text-container w-[52px]"></div>
                </div>
              </div>
            </div>
          ))}
    </div>
  );
};

export default ContentsContainer;
