import { AxiosError } from 'axios';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  followUserApi,
  getMyFollowingsApi,
  unfollowUserApi,
} from '../../../lib/apis/users';
import { GetMyFollowingsResponse } from '../../../lib/apis/users/response';
import { axiosErrorHandler } from '../../../lib/functions/handler';
import { addComma } from '../../../lib/functions/utils';
import { MemberDepartment } from '../../../lib/types/enums';
import { useAppSelector } from '../../../redux/app/hooks';
import { Spinner } from '../../Loader';
import { CheckIcon } from '@heroicons/react/outline';

interface ProfileContainerProps {
  userId: string;
  department: MemberDepartment;
  imageUrl: string;
  username: string;
  followers: number;
  introduce?: string;
}

const ProfileContainer: React.FC<ProfileContainerProps> = ({
  userId,
  department,
  imageUrl = '',
  username = '',
  followers = 0,
  introduce = '',
}) => {
  const user = useAppSelector((state) => state.users);
  const departments: Record<MemberDepartment, string> = {
    [MemberDepartment.PLANNER]: '기획',
    [MemberDepartment.DESIGNER]: '디자인',
    [MemberDepartment.DEVELOPER]: '개발',
  };
  const [followerCount, setFollowerCount] = useState(followers);
  const [isFollowing, setIsFollowing] = useState(false);
  const { data, isLoading, isError, refetch } = useQuery<
    GetMyFollowingsResponse,
    Error
  >(['getMyFollowings'], getMyFollowingsApi, { enabled: false });
  const { mutate: mutateFollowUser, isLoading: isFollowUserLoading } =
    useMutation(['followUser'], followUserApi, {
      onError: (e: AxiosError) => axiosErrorHandler(e),
      onSuccess: () => {
        setIsFollowing((prevState) => !prevState);
        setFollowerCount((prevState) => prevState + 1);
      },
    });
  const { mutate: mutateUnfollowUser, isLoading: isUnfollowUserLoading } =
    useMutation(['unfollowUser'], unfollowUserApi, {
      onError: (e: AxiosError) => axiosErrorHandler(e),
      onSuccess: () => {
        setIsFollowing((prevState) => !prevState);
        setFollowerCount((prevState) => prevState - 1);
      },
    });

  useEffect(() => {
    if (user.isLoggedIn) {
      refetch();
    }
  }, [user, refetch]);

  useEffect(() => {
    if (!isLoading && !isError) {
      if (data) {
        if (data.includes(userId)) {
          setIsFollowing(true);
        }
      }
    }
  }, [userId, data, isLoading, isError]);

  /** 팔로우 상태 및 팔로우 수 변경 */
  const onFollowButtonClick = () => {
    if (isFollowing) {
      mutateUnfollowUser({ userId });
    } else {
      mutateFollowUser({ userId });
    }
  };

  return (
    <div className="flex space-x-3">
      <Link href={`/profiles/${userId}`}>
        <a className="profile-image-container h-10 w-10 shrink-0 bg-gray-100 hover:shadow-profileContainer">
          <Image
            src={imageUrl}
            alt={`${username} Profile Image`}
            layout="fill"
            objectFit="cover"
            priority
          />
        </a>
      </Link>
      <div className="flex w-full items-center justify-between space-x-8 text-xs">
        <div className="space-y-1">
          <div className="space-x-2 text-lg font-bold">
            <span className="text-xs">{departments[department]}</span>
            <Link href={`/profiles/${userId}`}>
              <a className="text-base hover:opacity-70">{username}</a>
            </Link>
          </div>
          <div>팔로우 {addComma(followerCount)}명</div>
          <div className="break-all pt-[4px]">{introduce}</div>
        </div>
        {userId === user.userId ? (
          // 본인
          <Link href={`/profiles/${userId}`}>
            <a className="flex min-w-[89px] items-center justify-center whitespace-nowrap rounded-lg bg-gray-100 py-[14px] text-sm font-semibold text-gray-600 transition hover:bg-gray-300 active:bg-gray-400">
              내 프로필
            </a>
          </Link>
        ) : isFollowing ? (
          // 본인 아니고 팔로우 중
          <button
            onClick={onFollowButtonClick}
            disabled={isLoading || isError || isUnfollowUserLoading}
            className="flex min-w-[89px] items-center justify-center space-x-1 rounded-lg bg-gray-800 py-[11px] text-sm font-semibold text-white transition"
          >
            {isLoading || isError || isUnfollowUserLoading ? (
              <Spinner className="px-4 py-1" />
            ) : (
              <>
                <div className="whitespace-nowrap">팔로잉</div>
                <CheckIcon height={20} width={20} />
              </>
            )}
          </button>
        ) : (
          // 본인 아니고 팔로우 중 아님
          <button
            onClick={onFollowButtonClick}
            disabled={isLoading || isError || isFollowUserLoading}
            className="flex min-w-[89px] items-center justify-center whitespace-nowrap rounded-lg bg-gray-100 py-[14px] text-sm font-semibold text-gray-600 transition hover:bg-gray-300 active:bg-gray-400"
          >
            {isLoading || isError || isFollowUserLoading ? (
              <Spinner className="px-4 py-1" />
            ) : (
              '팔로우'
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default ProfileContainer;
